/*
 *  TTTech nerve-management-system
 *  Copyright(c) 2023. TTTech Industrial Automation AG.
 *
 *  ALL RIGHTS RESERVED.
 *
 *  Usage of this software, including source code, netlists, documentation,
 *  is subject to restrictions and conditions of the applicable license
 *  agreement with TTTech Industrial Automation AG or its affiliates.
 *
 *  All trademarks used are the property of their respective owners.
 *
 *  TTTech Industrial Automation AG and its affiliates do not assume any liability
 *  arising out of the application or use of any product described or shown
 *  herein. TTTech Industrial Automation AG and its affiliates reserve the right to
 *  make changes, at any time, in order to improve reliability, function or
 *  design.
 *
 *  Contact Information:
 *  support@tttech-industrial.com
 *
 *  TTTech Industrial Automation AG, Schoenbrunnerstrasse 7, 1040 Vienna, Austria
 *
 */

export default class DeployedComposeWorkloadServicesModel {
  /**
   * @description Node device sub model
   * @param data
   */
  constructor(data = {}) {
    this.containerName = data.containerName || '';
    this.image = data.imageName || '';
    this.serviceName = data.serviceName || '';
    this.status = data.status;
    this.lastStateChange = data.lastStateChange;
    this.configurationUpdateInfo = data.configurationUpdateInfo || {};
    this.configurationUpdateStatus = data.configurationUpdateStatus || {};
    this.environmentVariables = data;
    this.portList = data;
    // eslint-disable-next-line max-len, no-return-assign
    this.networks = data.networks ? data.networks.map((network) => (network = { name: network })) : [];
    this.volumes = data.volumes || [];
  }

  get environmentVariables() {
    return this._environmentVariables;
  }

  set environmentVariables(data) {
    if (data.envs && data.envs.length > 0) {
      this._environmentVariables = data.envs.map((element) => {
        if (typeof element === 'string') {
          element = { variable: element.split('=')[0], value: element.split('=')[1] };
        }
        return element;
      });
      return;
    }
    this._environmentVariables = data._environmentVariables || [];
  }

  get portList() {
    return this._portList;
  }

  set portList(data) {
    if (data.ports && data.ports.length > 0) {
      this._portList = data.ports.map((element) => {
        element.protocol = element.protocol.toUpperCase();
        return element;
      });
      return;
    }
    this._portList = data._portList || [];
  }
}
